import AES from 'crypto-js/aes';
import ENC from 'crypto-js/enc-utf8';
import Cookies from 'quasar/src/plugins/cookies/Cookies.js';
/**
 * Standard function to get authorization header
 * @param token auth token
 * @param skipCsrf
 * @returns {
 *  'x-csrftoken'?: csrf token,
 *  'Authorization'?: auth header
 * }
 */
export function authHeader(skipCsrf = false, userMisc = null) {
    if (process.env.SERVER) {
        // if this is in server, skip as it should already
        // been included in the cookie
        return {};
    }
    const csrfToken = Cookies.get('csrftoken');
    // decrypt token and append
    const token = userMisc && userMisc.access_token
        ? 'Bearer ' + AES.decrypt(userMisc.access_token, process.env.passphrase).toString(ENC) : null;
    return Object.assign(Object.assign({}, (
    // append csrf token
    csrfToken && !skipCsrf
        ? {
            'x-csrftoken': csrfToken
        } : {})), (
    // append token
    token ? {
        'Authorization': token
    } : {}));
}
